/* eslint-disable max-statements-per-line */
import {
  userData,
  userMessage
} from 'index'
import $ from 'jquery'

const style = getComputedStyle(document.body)
$.brandSecondary = style.getPropertyValue('--secondary')
$.brandSuccess = style.getPropertyValue('--success')
$.brandPrimary = style.getPropertyValue('--primary')
$.brandInfo = style.getPropertyValue('--info')
$.brandWarning = style.getPropertyValue('--warning')
$.brandDanger = style.getPropertyValue('--danger')
$.brandFont = style.getPropertyValue('--font-family-sans-serif')

Window.alert = (text, title) => {
  showModal(title, text, 'alert')
}

function searchParam(param) {
  let resp = false
  if (document.location.search.split('?').length > 1) {
    const params = document.location.search.split('?')[1].split('&')
    params.forEach((currentValue) => {
      if (currentValue.split('=')[0] === param) {
        resp = currentValue.replace(`${param}=`, '')
      }
    })
  }
  return resp
}

function showModal(title, content, type, callback, style) {
  let modalClass = ''
  if (type === 'alert') {
    modalClass = 'modal-danger'
  }
  if (type === 'confirm') {
    modalClass = 'modal-warning'
  }
  if (type === 'auth') {
    modalClass = 'modal-auth'
  }
  modalClass = style ? `modal-${style}` : modalClass
  const modal = `
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered ${modalClass}" role="document">
      <div class="modal-content">
        ${type === 'edit' || type === 'approve' || type === 'auth' ? '<form name="confirm-form" class="validate">' : ''}
        <div class="modal-header">
          ${type === 'auth' ? `
            <img class="" src="/img/logo.svg" alt="" height="32px"></img>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          ` : `
            <h5 class="modal-title">${title}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          `}
        </div>
        ${type === 'approve' || type === 'auth' ? `
          <div class="modal-body">
            ${content}
          </div>` : `
          <div class="modal-body">
            <p>${content}</p>
          </div>
        `}
        ${type === 'auth' ? `
          <div class="modal-footer"></div>
        </form>
        ` : `
          <div class="modal-footer">
            ${type === 'approve' ? `
              <button type="submit" class="btn btn-danger btn-ladda btn-no mr-auto">%%Отменить%%</button>
              <button type="button" class="btn btn-light" data-dismiss="modal">%%Закрыть%%</button>
              <button type="submit" class="btn btn-success btn-ladda btn-yes">%%Разрешить%%</button>
            ` : '<button type="button" class="btn btn-light" data-dismiss="modal">%%Закрыть%%</button>'}
            ${type === 'confirm' ? `<button type="submit" class="btn ${style ? `btn-${style}` : 'btn-primary'} btn-ladda btn-yes">Ok</button>` : ''}
            ${type === 'edit' ? '<button type="submit" class="btn btn-warning btn-ladda btn-yes">%%Сохранить%%</button>' : ''}
          </div>
          ${type === 'edit' || type === 'approve' ? '</form>' : ''}
        `}
      </div>
    </div>
  </div>`
  $('body').append(modal)
  if (typeof callback === 'function') {
    callback()
  }
  $('.modal').modal({
    backdrop: 'static'
  })
}

$(document).on('hidden.bs.modal', '.modal', function () {
  $(this).remove()
})

$(document).on('click', '.inside-control .btn-see', function (e) {
  e.preventDefault()
  const input = $(this).siblings('input')
  const password = input.is('input[type="password"]')
  const icon = $(this).find('i')
  icon.toggleClass('icon-eye', !password).toggleClass('icon-eye-crossed', password)
  if (password) {
    input.closest('form').find('.pared').attr('type', 'text')
  } else {
    input.closest('form').find('.pared').attr('type', 'password')
  }
})

function spinObject(obj) {
  const spinner = $(`
    <div class="mdc-circular-progress mdc-circular-progress--indeterminate" role="progressbar" aria-valuemin="0" aria-valuemax="1">
      <div class="mdc-circular-progress__indeterminate-container">
        <div class="mdc-circular-progress__spinner-layer">
          <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-left">
            <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="8.75" stroke-dasharray="54.978" stroke-dashoffset="27.489" stroke-width="2.5"/>
            </svg>
          </div>
          <div class="mdc-circular-progress__gap-patch">
            <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="8.75" stroke-dasharray="54.978" stroke-dashoffset="27.489" stroke-width="2"/>
            </svg>
          </div>
          <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-right">
            <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="8.75" stroke-dasharray="54.978" stroke-dashoffset="27.489" stroke-width="2.5"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  `)
  $(obj).prepend(spinner)
  return spinner
}

if (Object.prototype.hasOwnProperty.call($, 'validator')) {
  $.validator.setDefaults({
    errorElement: 'div',
    errorPlacement: (error, element) => {
      error.appendTo(element.closest('.form-group'))
    },
    validClass: '',
    errorClass: 'feedback invalid-feedback',
    highlight: (element) => {
      $(element).addClass('is-invalid')
      if ($(element).is('.radio')) {
        const name = $(element).attr('name')
        $(element).closest('form').find(`[name=${name}]`).addClass('is-invalid')
      }
    },
    unhighlight: (element) => {
      $(element).removeClass('is-invalid')
      if ($(element).is('.radio')) {
        const name = $(element).attr('name')
        $(element).closest('form').find(`[name=${name}]`).removeClass('is-invalid')
      }
    },
    onkeyup: function onkeyup(element, e) {
      if (!(e.keyCode === 13 && $('.summary-error').is('div'))) {
        $('.summary-error').remove()
      }
      $(element).valid()
    }
  })
}

$(document).on('click', '.sign-up', () => {
  if ($('.modal').is('.show')) {
    $('.modal').modal('hide')
  }
  showModal('', `
    <div class="header mb-4 px-2">%%Зарегистрируйтесь на HorseExpert.ru и вы сможете оценивать и комментировать публикации, создавать свои блоги и подписываться на чужие.%%</div>
    <div class="form-group mb-3">
      <input name="name" class="form-control form-control-lg" type="text" placeholder="%%Имя%%">
    </div>
    <div class="form-group mb-3">
      <input name="mail" class="form-control form-control-lg" type="text" placeholder="%%Электронная почта%%">
    </div>
    <div class="form-group mb-3">
      <input name="password" class="form-control form-control-lg pared" type="password" placeholder="%%Пароль%%">
    </div>
    <div class="form-group inside-control">
      <input name="password2" class="form-control form-control-lg pared" type="password" placeholder="%%Подтвердить пароль%%">
      <a href="" class="btn-see text-warning">
        <i class="icon-eye"></i>
      </a>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="check1" name="agree" required>
      <label class="custom-control-label" for="check1">%%Я согласен с%% <a href="/policy.html" target="_blank">%%Правилами пользования%%</a></label>
    </div>
    <button class="btn btn-lg btn-warning mt-4" type="submit">%%Сохранить%%</button>
    <a class="sign-in" role="button">%%Авторизироваться%%</a>
  `, 'auth', () => {
    const validator = $('.validate').validate({
      rules: {
        name: {
          required: true,
          minlength: 4,
          maxlength: 32
        },
        mail: {
          required: true,
          email: true
        },
        password: {
          required: true,
          rangelength: [8, 32]
        },
        password2: {
          required: true,
          rangelength: [8, 32],
          equalTo: $('form').find('input[name="password"]')
        }
      },
      submitHandler: () => {
        const spinner  = spinObject($('.modal [type="submit"]'))
        $.ajax({
          dataType: 'json',
          type: 'post',
          url: '/api/signup.json',
          data: $('form[name=confirm-form]').serialize(),
          beforeSend: () => {
            $('.summary-error').remove()
            $('.glass').show()
            spinner.animate({
              width: 24,
              'margin-right': 4
            })
          },
          success: (data) => {
            spinner.animate({
              width: 0,
              'margin-right': 0
            })
            $('.glass').hide()
            if (data.error) {
              if (typeof data.error === 'string') {
                $('form .form-group').last().append(`<div class="summary-error" style="">${data.error}</div>`)
              } else {
                validator.showErrors(data.error)
              }
            } else {
              $('.modal').modal('hide')
              userData = data.userData
              userLoad()
              showConfirm()
            }
          },
          error: (jqXHR, textStatus, errorThrown) => {
            window.console.log(jqXHR, textStatus, errorThrown)
          }
        })
      }
    })
  })
})

$(document).on('click', '.sign-in', () => {
  signIn()
})

function signIn(confirmation) {
  if ($('.modal').is('.show')) {
    $('.modal').modal('hide')
  }
  showModal('', `
    ${confirmation ? `<input type="hidden" name="confirmation" value="${confirmation}">` : ''}
    <div class="header mb-4 px-2">%%Авторизируйтесь на HorseExpert.ru и вы сможете оценивать и комментировать публикации, создавать свои блоги и подписываться на чужие.%%</div>
    <div class="form-group mb-3">
      <input name="mail" class="form-control form-control-lg" type="text" placeholder="%%Электронная почта%%">
    </div>
    <div class="form-group inside-control mb-0">
      <input name="password" class="form-control form-control-lg pared" type="password" placeholder="%%Пароль%%">
      <a href="" class="btn-see text-warning">
        <i class="icon-eye"></i>
      </a>
    </div>
    <a class="forgot" role="button">%%Забыли пароль?%%</a>
    <button class="btn btn-lg btn-warning mt-4" type="submit">%%Войти%%</button>
    <a class="sign-up" role="button">%%Зарегистрироваться%%</a>
  `, 'auth', () => {
    const validator = $('.validate').validate({
      rules: {
        mail: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 8
        }
      },
      submitHandler: () => {
        const spinner  = spinObject($('.modal [type="submit"]'))
        $.ajax({
          dataType: 'json',
          type: 'post',
          url: '/api/login.json',
          data: $('form[name=confirm-form]').serialize(),
          beforeSend: () => {
            $('.glass').show()
            spinner.animate({
              width: 24,
              'margin-right': 4
            })
          },
          success: (data) => {
            spinner.animate({
              width: 0,
              'margin-right': 0
            })
            $('.glass').hide()
            if (data.error) {
              if (typeof data.error === 'string') {
                $('form .form-group').last().append(`<div class="summary-error" style="">${data.error}</div>`)
              } else {
                validator.showErrors(data.error)
              }
            } else {
              $('.modal').modal('hide')
              userData = data.userData
              userLoad()
              if (confirmation) {
                showModal('', `
                <div class="header mb-4 px-2">%%Ваш электронный адрес подтвержден.%%</div>
                <button class="btn btn-lg btn-warning mt-3" type="button" data-dismiss="modal">%%Закрыть%%</button>
              `, 'auth')
              } else {
                showConfirm()
              }
            }
          },
          error: (jqXHR, textStatus, errorThrown) => {
            window.console.log(jqXHR, textStatus, errorThrown)
          }
        })
      }
    })
  })
}

$(document).on('click', '.forgot, .change-password', () => {
  if ($('.modal').is('.show')) {
    $('.modal').modal('hide')
  }
  showModal('', `
    <div class="header mb-4 px-2">%%Введите вашу электронную почту и мы вышлем вам ссылку с помощью которой вы сможете обновить пароль%%</div>
    <div class="form-group mb-3">
      <input name="mail" class="form-control form-control-lg" type="text" placeholder="%%Почта%%">
    </div>
    <button class="btn btn-lg btn-warning mt-4" type="submit">%%Оправить%%</button>
    <a class="sign-in" role="button">%%Авторизироваться%%</a>
  `, 'auth', () => {
    const validator = $('.validate').validate({
      rules: {
        mail: {
          required: true,
          email: true
        }
      },
      submitHandler: () => {
        const spinner  = spinObject($('.modal [type="submit"]'))
        $.ajax({
          dataType: 'json',
          type: 'post',
          url: '/api/forgot.json',
          data: $('form[name=confirm-form]').serialize(),
          beforeSend: () => {
            $('.glass').show()
            spinner.animate({
              width: 24,
              'margin-right': 4
            })
          },
          success: (data) => {
            spinner.animate({
              width: 0,
              'margin-right': 0
            })
            $('.glass').hide()
            if (data.error) {
              if (typeof data.error === 'string') {
                $('form .form-group').last().append(`<div class="summary-error" style="">${data.error}</div>`)
              } else {
                validator.showErrors(data.error)
              }
            } else {
              $('.modal-body').html(`
                <div class="header mb-4 px-2">%%На указанную вами электронную почту была выслана ссылка. Пройдите по ней и вы сможете сбросить забытый пароль%%</div>
                <button class="btn btn-lg btn-warning mt-2" type="button" data-dismiss="modal">%%Закрыть%%</button>
              `)
            }
          },
          error: (jqXHR, textStatus, errorThrown) => {
            window.console.log(jqXHR, textStatus, errorThrown)
          }
        })
      }
    })
  })
})

if (searchParam('reset').length > 0) {
  if ($('.modal').is('.show')) {
    $('.modal').modal('hide')
  }
  showModal('', `
    <input name="reset" type="hidden" value="${searchParam('reset')}">
    <div class="header mb-4 px-2">%%Придумайте новый пароль и мы сохраним его%%</div>
    <div class="form-group mb-3">
      <input name="password" class="form-control form-control-lg pared" type="password" placeholder="%%Пароль%%">
    </div>
    <div class="form-group inside-control mb-0">
      <input name="password2" class="form-control form-control-lg pared" type="password" placeholder="%%Подтвердить пароль%%">
      <a href="" class="btn-see text-warning">
        <i class="icon-eye"></i>
      </a>
    </div>
    <button class="btn btn-lg btn-warning mt-4" type="submit">%%Сохранить%%</button>
  `, 'auth', () => {
    history.pushState(null, null, '/')
    const validator = $('.validate').validate({
      rules: {
        password: {
          required: true,
          rangelength: [8, 32]
        },
        password2: {
          required: true,
          rangelength: [8, 32],
          equalTo: $('form').find('input[name="password"]')
        }
      },
      submitHandler: () => {
        const spinner  = spinObject($('.modal [type="submit"]'))
        $.ajax({
          dataType: 'json',
          type: 'post',
          url: '/api/reset.json',
          data: $('form[name=confirm-form]').serialize(),
          beforeSend: () => {
            $('.glass').show()
            spinner.animate({
              width: 24,
              'margin-right': 4
            })
          },
          success: (data) => {
            spinner.animate({
              width: 0,
              'margin-right': 0
            })
            $('.glass').hide()
            if (data.error) {
              if (typeof data.error === 'string') {
                $('form .form-group').last().append(`<div class="summary-error" style="">${data.error}</div>`)
              } else {
                validator.showErrors(data.error)
              }
            } else {
              $('.modal').modal('hide')
              userData = data.userData
              userLoad()
              showConfirm()
            }
          },
          error: (jqXHR, textStatus, errorThrown) => {
            window.console.log(jqXHR, textStatus, errorThrown)
          }
        })
      }
    })
  })
}

$(document).on('click', '.logout', () => {
  $.ajax({
    dataType: 'json',
    type: 'post',
    url: '/api/logout.json',
    success: (data) => {
      if (data.error) {
        window.console.log(data.error)
      } else {
        authorized = false
        userData = false
        $('a.profile')
          .dropdown('dispose')
          .css('background-image', 'none')
          .attr('data-original-title', '%%Авторизироваться%%')
          .addClass('sign-in icon-avatar')
          .attr('id', '')
          .attr('data-toggle', 'tooltip')
          .attr('aria-haspopup', 'true')
          .attr('aria-expanded', 'false')
          .siblings('.dropdown-menu')
          .remove()
        $('.likes a').addClass('disabled')
        $('.likes a').removeClass('text-primary')
        $('.add-comment').addClass('disabled')
        $('.btn-subscribe').removeClass('active').text('%%Подписаться%%')
        $('.btn-subscribe-post').addClass('d-none').removeClass('active').html('<i class="icon-edit"></i>%%Подписаться%%')
        if (!$('.feed').is('div')) {
          location.href = '/index.html'
        }
        $('.subscriptions').remove()
        $('.notifications').remove()
        $('.filter .btn').addClass('disabled').removeClass('active').first().addClass('active')
        page = 0
        if (section !== 'magazine') {
          $('.feed').empty()
          feedLoader(0)
        }
      }
    },
    error: (jqXHR, textStatus, errorThrown) => {
      window.console.log(jqXHR, textStatus, errorThrown)
    }
  })
})

function getCookie(cname) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

function getFormData($form) {
  const unindexed = $form.serializeArray()
  const indexed = {}

  $.map(unindexed, (n) => {
    indexed[n.name] = n.value
  })
  return JSON.stringify(indexed)
}

if (getCookie('gdpr')) {
  setGDPR()
} else {
  $('body').append(`
    <div class="gdpr">
      <input type="checkbox" id="gdpr-checkbox">
      <div>
        <p data-lang="gdpr-text">%%Наш сайт использует cookie-файлы, чтобы улучшить вашу работу. Мы предполагаем, что вы согласны с этим, но можете отказаться, если хотите.%%</p>
        <div>
          <label class="btn btn-sm btn-warning mb-0" for="gdpr-checkbox" data-lang="gdpr-accept">%%Принять%%</label>
          <a href="/policy.html" class="btn btn-sm text-primary" target="blank" data-lang="gdpr-more">%%Узнать больше%%</a>
        </div>
      </div>
    </div>
  `)
}

$(document).on('change', '#gdpr-checkbox', () => {
  setGDPR()
})

function setGDPR() {
  const d = new Date()
  d.setTime(d.getTime() + 3600000)
  document.cookie = `gdpr=true; expires=${d.toUTCString()} path=/;`
}

$(document).on('click', '[title]', function () {
  $(this).tooltip('hide')
})

$(document).on('click', '.feed .translate', function () {
  const elem = $(this).closest('.feed-item')
  const original = $(elem).is('.original')
  const item = cash[$(elem).attr('translate-id')]
  const newTitle = original ? item.title : item.titleOrig
  const newAnons = original ? item.anons : item.anonsOrig
  const title = $(elem).find('.title')
  const anons = $(elem).find('.anons')
  if (newTitle !== $(title).text() || newAnons !== $(anons).text()) {
    $(title).animate({
      opacity: 0
    }, () => {
      $(title).text(newTitle).animate({
        opacity: 1
      })
    })
    $(anons).animate({
      opacity: 0
    }, () => {
      $(anons).text(newAnons).animate({
        opacity: 1
      })
    })
  }
  $(this).attr('data-original-title', `${original ? '%%Показать оригинал%%' : '%%Показать перевод%%'}`)
  $(elem).toggleClass('original', !original)
})

$(document).on('click', '.chanel .translate', function () {
  const original = $('.post-body').is('.original')
  const objs = $('.post-body .title, .post-body .title-orig, .post-body .body, .post-body .body-orig')
  $(this).attr('data-original-title', `${original ? '%%Показать оригинал%%' : '%%Показать перевод%%'}`)
  $(objs).animate({
    opacity: 0
  }, () => {
    $('.post-body').toggleClass('original', !original)
    $(objs).animate({
      opacity: 1
    })
  })
})

const quantity = 16
let channel = 0
if (location.href.search('channel.html') > -1) {
  channel = searchParam('id')
}
let authorized = false
function userLoad() {
  authorized = true
  $('a.profile')
    .css('background-image', `url(/img/users/${userData.id}.jpg)`)
    .removeClass('sign-up sign-in icon-avatar')
    .attr('data-original-title', '')
  $('.dropdown a.profile')
    .attr('data-original-title', '%%Профиль%%')
    .attr('id', 'dropdownMenuButton')
    .attr('data-toggle', 'dropdown')
    .attr('aria-haspopup', 'true')
    .attr('aria-expanded', 'false')
    .after(`
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <div class="dropdown-item-text username">${userData.name}</div>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item btn-profile" role="button">%%Профиль%%</a>
        ${Object.prototype.hasOwnProperty.call(userData, 'blog') && userData.blog ? '<a href="/publications.html" class="dropdown-item" role="button">%%Публикации%%</a>' : ''}
        <div class="dropdown-divider"></div>
        <a class="dropdown-item logout" role="button">%%Выйти%%</a>
      </div>
    `)
  if (userData.confirmed) {
    $('.likes a').removeClass('disabled')
    $('.add-comment').removeClass('disabled')
    $('.btn-subscribe-post').removeClass('d-none')
    $('.filter .btn').removeClass('disabled')
  }
  $('.subscriptions').remove()
  if (Object.prototype.hasOwnProperty.call(userData, 'subscriptions') && Object.keys(userData.subscriptions).length > 0) {
    $('.social').before(`
      <ul class="subscriptions"></ul>
      <div class="spacer subscriptions"></div>
    `)
    for (const id in userData.subscriptions) {
      if (Object.prototype.hasOwnProperty.call(userData.subscriptions, id)) {
        const title = userData.subscriptions[id]
        $('ul.subscriptions').append(`
          <li><a href="/channel.html?id=${id}" class="btn section" data-channel="${id}" data-title="${title}"><div class="avatar" style="background-image: url(/img/avatars/${id}.jpg);"></div>${title}</a></li>
        `)
        if (channel === id) {
          $('.side-menu a.active').removeClass('active')
          $(`[data-channel=${id}]`).addClass('active')
        }
        if ($('.btn-subscribe').attr('data-id') === id) {
          $('.btn-subscribe').text('%%Отписаться%%').addClass('subscribed')
        }
      }
    }
  }
  $('.notifications').remove()
  if (Object.prototype.hasOwnProperty.call(userData, 'notifications') && (Object.prototype.hasOwnProperty.call(userData.notifications, 'whatsapp') || Object.prototype.hasOwnProperty.call(userData.notifications, 'telegram'))) {
    $('.social').before(`
    <div class="notifications">
      ${Object.prototype.hasOwnProperty.call(userData.notifications, 'whatsapp') ? `
      <a href="https://wa.me/${userData.notifications.phone}/?text=%%Здравствуйте!%0AЧтобы подписаться на рассылку Horsexpert.ru отправьте это сообщение не изменяя его.%%%0A${userData.notifications.id}" target="_blank" class="btn btn-lg btn-info ${userData.notifications.whatsapp ? 'text-primary' : ''} whatsapp" data-toggle="tooltip" data-title="%%Оповещение через Whatsapp%%">
        <i class="icon-whatsapp"></i>
      </a>` : ''}
      ${Object.prototype.hasOwnProperty.call(userData.notifications, 'telegram') ? `
      <a href="https://t.me/${userData.notifications.botname}?start=${userData.notifications.id}" target="_blank" class="btn btn-lg btn-info ${userData.notifications.telegram ? 'text-primary' : ''} telegram" data-toggle="tooltip" data-title="%%Оповещение через Telegram%%">
        <i class="icon-telegram"></i>
      </a>` : ''}
    </div>
    <div class="spacer subscriptions"></div>
  `)
  }
  loadLikes()
}

$(document).on('click', '.notifications .btn', function (e) {
  const obj = $(this)
  const action = !$(obj).is('.text-primary')
  if (!action) {
    e.preventDefault()
    const action = !$(obj).is('.text-primary')
    const messenger = $(obj).is('.whatsapp') ? 'whatsapp' : 'telegram'
    $.ajax({
      dataType: 'json',
      type: 'post',
      url: '/api/notifications.json',
      data: `${messenger}=${action}`,
      success: (data) => {
        if (data.error) {
          window.console.log(data.error)
        } else {
          userData.notifications[messenger] = action
          $(obj).toggleClass('text-primary', action)
        }
      }
    })
  } else {
    $(obj).toggleClass('text-primary', action)
  }
})

function showConfirm() {
  if (!userData.confirmed) {
    showModal('', `
      <div class="header mb-4 px-2">%%Ваш электронный адрес не подтвержден. При регистрации вам была выслана ссылка пройдя по которой вы сможете это сделать. Без подтверждения електронного адреса функционал будет неполным.%%</div>
        <button class="btn btn-lg btn-warning mt-2 send-confirm" type="button">%%Послать повторно%%</button>
        <button class="btn btn-lg btn-warning mt-3" type="button" data-dismiss="modal">%%Закрыть%%</button>
    `, 'auth')
  }
}

function loadLikes() {
  if (userData && userData.confirmed && Object.keys(cash).length > 0) {
    userData.likes.forEach((id) => {
      $(`.feed-item[data-id=${id}] a.like`).addClass('text-primary')
      $(`.likes[data-id=${id}] a.like`).addClass('text-primary')
    })
    userData.dislikes.forEach((id) => {
      $(`.feed-item[data-id=${id}] a.dislike`).addClass('text-primary')
      $(`.likes[data-id=${id}] a.dislike`).addClass('text-primary')
    })
    if (authorized) {
      $('.btn-subscribe-post').removeClass('d-none')
    }

    for (const id in userData.subscriptions) {
      if (Object.prototype.hasOwnProperty.call(userData.subscriptions, id)) {
        $(`.btn-subscribe-post[data-id=${id}]`).html('<i class="icon-edit"></i>%%Отписаться%%').addClass('subscribed')
      }
    }
  }
}

if (Object.prototype.hasOwnProperty.call(window, 'userMessage')) {
  userMessage()
}

$(document).on('click', 'a.like, a.dislike', function () {
  if (userData) {
    if (userData.confirmed) {
      const obj = $(this)
      const action = $(obj).is('.like') ? 'like' : 'dislike'
      $.ajax({
        dataType: 'json',
        type: 'post',
        url: '/api/like.json',
        data: `id=${$(obj).closest('[data-id]').attr('data-id')}&action=${action}&status=${$(obj).is('.text-primary') ? '0' : '1'}`,
        beforeSend: () => {
          $('.glass').show()
          $(obj).find('i').toggleClass(`icon-${action} icon-spinner icon-spin`)
        },
        success: (data) => {
          $(obj).find('i').toggleClass(`icon-${action} icon-spinner icon-spin`)
          $('.glass').hide()
          if (data.error) {
            window.console.log(data.error)
          } else {
            const counter = $(`.subtitle .${action} b`)
            const status = $(obj).is('.text-primary') ? -1 : 1
            $(counter).text(Number($(counter).text()) + status)
            $(obj).toggleClass('text-primary')
          }
        },
        error: (jqXHR, textStatus, errorThrown) => {
          window.console.log(jqXHR, textStatus, errorThrown)
        }
      })
    } else {
      showConfirm()
    }
  } else {
    signIn()
  }
})

$(document).on('click', '.btn-subscribe, .btn-subscribe-post', function () {
  if (userData) {
    if (userData.confirmed) {
      const obj = $(this)
      const subscribed = $(obj).is('.subscribed')
      const action = $(obj).is('.subscribed') ? 0 : 1
      const id = $(obj).attr('data-id')
      $.ajax({
        dataType: 'json',
        type: 'post',
        url: '/api/subscribe.json',
        data: `id=${id}&action=${action}`,
        beforeSend: () => {
          $('.glass').show()
          $(obj).prepend('<i class="icon-spinner icon-spin"></i>')
        },
        success: (data) => {
          $(obj).find('i').remove()
          $('.glass').hide()
          if (data.error) {
            window.console.log(data.error)
          } else {
            if ($('.likes').attr('data-id') === id || channel === id) {
              const subscribers = $('.subscribers span').first()
              const status = subscribed ? -1 : 1
              const counter = Number($(subscribers).text()) + status
              $(subscribers).text(counter)
              $('.subscribers span').last().text(declOfNum(counter, ['%%подписчик%%', '%%подписчика%%', '%%подписчиков%%']))
            }
            $(`.btn-subscribe-post[data-id=${id}]`).html(subscribed ? '<i class="icon-edit"></i>Подписаться' : '<i class="icon-edit"></i>%%Отписаться%%').toggleClass('subscribed')
            $(`.btn-subscribe[data-id=${id}]`).text(subscribed ? '%%Подписаться%%' : '%%Отписаться%%').toggleClass('subscribed')
          }
          if (Object.prototype.hasOwnProperty.call(userData.subscriptions, id)) {
            delete userData.subscriptions[id]
          } else {
            userData.subscriptions[id] = $(obj).closest('.feed-item').find('.source').text() || $('.channel-header .title').text() || $('.channel .title').text()
          }
          userLoad()
        },
        error: (jqXHR, textStatus, errorThrown) => {
          window.console.log(jqXHR, textStatus, errorThrown)
        }
      })
    } else {
      showConfirm()
    }
  } else {
    signIn()
  }
})

function declOfNum(n, forms) {
  n = Math.abs(n) % 100
  const n1 = n % 10
  if (n > 10 && n < 20) {
    return forms[2]
  }
  if (n1 > 1 && n1 < 5) {
    return forms[1]
  }
  if (n1 === 1) {
    return forms[0]
  }
  return forms[2]
}

$(document).on('click', '.send-confirm', function () {
  const spinner  = spinObject($(this))
  $.ajax({
    dataType: 'json',
    type: 'post',
    url: '/api/confirm.json',
    data: $('form[name=confirm-form]').serialize(),
    beforeSend: () => {
      $('.glass').show()
      spinner.animate({
        width: 24,
        'margin-right': 4
      })
    },
    success: (data) => {
      spinner.animate({
        width: 0,
        'margin-right': 0
      })
      $('.glass').hide()
      if (data.error) {
        window.console.log(data.error)
      } else {
        $('.modal-body').html(`
          <div class="header mb-4 px-2">%%На вашу электронную почту была выслана ссылка. Пройдите по ней и вы сможете подтвердить свою электронную почту%%</div>
          <button class="btn btn-lg btn-warning mt-2" type="button" data-dismiss="modal">%%Закрыть%%</button>
        `)
      }
    },
    error: (jqXHR, textStatus, errorThrown) => {
      window.console.log(jqXHR, textStatus, errorThrown)
    }
  })
})

const cash = {}
if (userData) {
  $('[data-toggle="tooltip"]').tooltip()
  userLoad()
}

let page = 0
let post = 0
let search = ''
const players = []
let section = ''
if (searchParam('section').length > 0) {
  section = searchParam('section')
  history.pushState(null, null, '/')
  $('.side-menu .section.active').removeClass('active')
  $(`[data-section=${section}]`).addClass('loading active')
  if (section === 'magazine') {
    magazineLoader()
  } else if (section === 'managers') {
    managersLoader()
  } else if (section === 'docs') {
    docsLoader()
  } else {
    feedLoader(0)
  }
} else {
  feedLoader(0)
}

function feedLoader(postFlag) {
  $('.feed-header .title .section').text($(`[data-section=${section === '' ? 'feed' : section}]`).attr('data-title'))
  if ($('.feed').is('div')) {
    post = postFlag
    $.ajax({
      dataType: 'json',
      type: 'POST',
      contentType: 'application/json; charset=UTF-8',
      url: '/api/news.json',
      data: JSON.stringify({
        page,
        items: quantity,
        post,
        search,
        section,
        channel: Number(channel),
        filter: Number($('.filter .active').attr('data-filter'))
      }),
      beforeSend: function beforeSend() {
        $(document).off('scroll')
        $('.backdrop').show()
      },
      success: function success(data) {
        if (data.error) {
          window.console.log('Feed loading error!')
        } else {
          $('.side-menu .loading').removeClass('loading').addClass('section')
          const items = data
          for (let i = 0; i < items.length; i++) {
            $('main').removeClass('magazine')
            const item = items[i]
            cash[item.id] = item
            const translated = item.title.length > 0 && item.title !== item.titleOrig
            const title = translated ? item.title : item.titleOrig
            const anons = translated ? item.anons : item.anonsOrig
            const titleSize = $('<span />').html(item.title).text().length * (1 + 0.2 * item.uppRatio / 100)
            let textClass = 'length-1'
            if (titleSize > 30) {
              textClass = 'length-2'
            }
            if (titleSize > 45) {
              textClass = 'length-3'
            }
            const imageRatio = item.width >= item.heigth ? 'horizontal' : 'vertical'

            if (item.video.length > 0) {
              const video = item.video.split('?')[0]
              $('.feed').append(`
                <div class="feed-item thumbed ${changeColor(item.color)}" data-id="${item.id}" data-video="${video}" ${translated ? `translate-id="${item.id}"` : ''}>
                  <div class="thumbnail" style="background-image: url(/images/t${item.picture}.jpg)">
                  <svg xmlns="http://www.w3.org/2000/svg" style="isolation: isolate;" viewBox="0 0 546.134 384" width="68" height="48">
                    <g>
                      <path
                        d=" M 534.722 60.083 C 528.441 36.433 509.935 17.807 486.438 11.486 C 443.848 0 273.067 0 273.067 0 C 273.067 0 102.287 0 59.696 11.486 C 36.199 17.808 17.693 36.433 11.412 60.083 C 0 102.95 0 192.388 0 192.388 C 0 192.388 0 281.826 11.412 324.693 C 17.693 348.343 36.199 366.193 59.696 372.514 C 102.287 384 273.067 384 273.067 384 C 273.067 384 443.847 384 486.438 372.514 C 509.935 366.193 528.441 348.343 534.722 324.693 C 546.134 281.826 546.134 192.388 546.134 192.388 C 546.134 192.388 546.134 102.95 534.722 60.083 L 534.722 60.083 Z "
                        fill="var(--youtubecolor)" />
                      <path d=" M 217.212 273.591 L 217.212 111.185 L 359.951 192.39 L 217.212 273.591 L 217.212 273.591 Z "
                        fill="rgb(255,255,255)" />
                    </g>
                  </svg>
                  </div>
                  <div class="cover"></div>
                  <div class="video-footer" style="background-color: rgb(${item.color});">
                    <h2 class="title">${title}</h2>
                    <div class="footer">
                      <a href="${item.url}" target="_blank" class="source">${item.source}</a>
                      <div class="likes">
                        <a role="button" class="like disabled"><i class="icon-like"></i></a>
                        <a role="button" class="dislike disabled"><i class="icon-dislike"></i></a>
                      </div>
                    </div>
                  </div>
                  <a class="text-${changeColor(item.color)} complaint" role="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icon-more"></i></a>
                  <div class="dropdown-menu">
                    <a role="button" class="dropdown-item video_complaint"><i class="icon-report"></i>%%Пожаловаться на публикацию%%</a>
                    <a role="button" class="dropdown-item text-warning btn-subscribe-post d-none" data-id="${item.channel}"><i class="icon-edit"></i>%%Подписаться%%</a>
                    <a role="button" class="dropdown-item text-danger delete-video d-none"><i class="icon-bin"></i>%%Удалить публикацию навсегда%%</a>
                  </div>
                  ${translated ? `<a class="translate text-${changeColor(item.color)}" data-toggle="tooltip" title="%%Показать оригинал%%"><i class="icon-language"></i></a>` : ''}
                </div>
              `)
            } else if (item.redirect === 2) {
              const link = item.url
              $('.feed').append(`
                <div class="feed-item ${changeColor(item.color)}" data-id="${item.id}" style="background-color: rgb(${item.color});">
                  <div class="image ${imageRatio} ${title === '' && anons === '' ? 'w-100 h-100' : ''}" style="background-image: url(/img/promo/${item.picture}.jpg)"></div>
                  ${title === '' && anons === '' ? '' : `
                    <div class="gradient vertical" style="background-image:linear-gradient(to bottom, rgba(${item.color},0) 55%, rgba(${item.color},0.6) 70%, rgba(${item.color},0.85) 80%, rgba(${item.color},1) 95%)"></div>
                    <div class="gradient horizontal" style="background:radial-gradient(100% 500% at 100%, rgba(${item.color},0) 55%, rgba(${item.color},0.6) 70%, rgba(${item.color},0.85) 80%, rgba(${item.color},1) 95%)"></div>
                  `}
                  <div class="hover"></div>
                  <a class="link promo-link" target="_blank" href="${link}"></a>
                  <div class="text ${textClass}">
                    ${title === '' && anons === '' ? '' : `
                      <h2 class="title">${title}</h2>
                      <div class="anons">${anons}</div>
                    `}
                    <div class="footer ${title === '' && anons === '' ? 'mt-auto' : ''}">
                      <span class="source">Реклама</span>
                    </div>
                  </div>
                </div>
              `)
            } else {
              const link = item.redirect ? item.url : `/news/${encodeURIComponent(translit(item.title))}-${item.id}.html`
              $('.feed').append(`
                <div class="feed-item ${changeColor(item.color)}" data-id="${item.id}" style="background-color: rgb(${item.color});" ${translated ? `translate-id="${item.id}"` : ''}>
                  <div class="image ${imageRatio}" style="background-image: url(/images/t${item.picture}.jpg)"></div>
                  <div class="gradient vertical" style="background-image:linear-gradient(to bottom, rgba(${item.color},0) 55%, rgba(${item.color},0.6) 70%, rgba(${item.color},0.85) 80%, rgba(${item.color},1) 95%)"></div>
                  <div class="gradient horizontal" style="background:radial-gradient(100% 500% at 100%, rgba(${item.color},0) 55%, rgba(${item.color},0.6) 70%, rgba(${item.color},0.85) 80%, rgba(${item.color},1) 95%)"></div>
                  <div class="hover"></div>
                  <a class="link" target="_blank" href="${link}"></a>
                  <div class="text ${textClass}">
                    <h2 class="title">${title}</h2>
                    <div class="anons">${anons}</div>
                    <div class="footer">
                      <a href="${item.url}" target="_blank" class="source">${item.source}</a>
                      <div class="likes">
                        <a role="button" class="like disabled"><i class="icon-like"></i></a>
                        <a role="button" class="dislike disabled"><i class="icon-dislike"></i></a>
                      </div>
                    </div>
                  </div>
                  ${translated ? `<a class="translate text-${changeColor(item.color)}" data-toggle="tooltip" title="%%Показать оригинал%%"><i class="icon-language"></i></a>` : ''}
                </div>
              `)
            }
          }
          loadLikes()
          page++
          if (items.length === quantity) {
            $(document).on('scroll', scrolling)
          }
          $('[data-toggle="tooltip"]').tooltip()
        }
      },
      error: function error() {
        window.console.log('Error! login.json')
      }
    })
  }
}

function docsLoader() {
  $.ajax({
    dataType: 'html',
    type: 'GET',
    contentType: 'application/json; charset=UTF-8',
    url: '/api/documents.html',
    beforeSend: function beforeSend() {
      $(document).off('scroll')
      $('.backdrop').show()
    },
    success: function success(data) {
      $('main').addClass('magazine')
      $('.side-menu .loading').removeClass('loading').addClass('section')
      $('.feed').append(data)
    }
  })
}

function managersLoader() {
  $('main').addClass('magazine')
  $('.side-menu .loading').removeClass('loading').addClass('section')
  $('.feed').append(`
  <div class="feed-header">
    <div class="title">${$(`[data-section=${section}]`).attr('data-title')}</div>
    <div class="managers">
      <div class="manager">
        <div class="avatar" style="background-image: url(/img/managers/sechina.jpg);"></div>
        <h5>%%М.В. Сечина%%</h5>
        <div>%%Президент Евразийской Ассоциации%%</div>
      </div>
      <div class="manager">
        <div class="avatar" style="background-image: url(/img/managers/mamitov.jpg);"></div>
        <h5>%%Садыр Мамытов%%</h5>
        <div>%%Первый вице-президент ЕЕА%%</div>
      </div>
      <div class="manager">
        <div class="avatar" style="background-image: url(/img/managers/aristov.jpg);"></div>
        <h5>%%Николай Аристов%%</h5>
        <div>%%Вице-президент ЕЕА%%</div>
      </div>
      <div class="manager">
        <div class="avatar" style="background-image: url(/img/managers/gaziev.jpg);"></div>
        <h5>%%Бахромжон Газиев%%</h5>
        <div>%%Вице-президент ЕЕА%%</div>
      </div>
      <div class="manager">
        <div class="avatar" style="background-image: url(/img/managers/agadjanian.jpg);"></div>
        <h5>%%Армен Агаджанян%%</h5>
        <div>%%Вице-президент ЕЕА%%</div>
      </div>
      <div class="manager">
        <div class="avatar" style="background-image: url(/img/managers/tuchak.jpg);"></div>
        <h5>%%Александр Тучак%%</h5>
        <div>%%Вице-президент ЕЕА%%</div>
      </div>
      <div class="manager span-2">
        <div class="avatar" style="background-image: url(/img/managers/buykevich.jpg);"></div>
        <h5>%%Сергей Буйкевич%%</h5>
        <div>%%Генеральный секретарь ЕЕА%%</div>
      </div>
    </div>
  <div>
`)
}

function magazineLoader() {
  $.ajax({
    dataType: 'json',
    type: 'POST',
    contentType: 'application/json; charset=UTF-8',
    url: '/api/magazine.json',
    beforeSend: function beforeSend() {
      $(document).off('scroll')
      $('.backdrop').show()
    },
    success: function success(data) {
      if (data.error) {
        window.console.log('Magazine loading error!')
      } else {
        $('main').addClass('magazine')
        $('.side-menu .loading').removeClass('loading').addClass('section')
        const groupped = {}
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          const year = `_${String(item.year)}`
          if (!Object.prototype.hasOwnProperty.call(groupped, year)) {
            groupped[year] = []
          }
          groupped[year].push(item)
        }
        for (const year in groupped) {
          if (Object.prototype.hasOwnProperty.call(groupped, year)) {
            $('.feed').append(`
            <div class="feed-header">
              <div class="title">${year.replace('_', '')}</div>
            </div>`)
            const items = groupped[year]
            for (let i = 0; i < items.length; i++) {
              const item = items[i]
              $('.feed').append(`
              <div class="magazine-item">

                <a class="link" target="_blank" href="${item.url}">
                  <img src="/img/magazine/${item.img}">
                </a>
              </div>
            `)
            }
          }
        }
        $('[data-toggle="tooltip"]').tooltip()
      }
    }
  })
}

$(document).on('mouseenter', '.feed-item.thumbed', function () {
  const cover = $(this).find('.cover')
  const id = $(this).attr('data-id')
  if ($(cover).css('display') === 'block') {
    const thumbnail = $(this).find('.thumbnail')
    const footer = $(this).find('.video-footer')
    if (!cover.is('.active')) {
      $(footer).animate({
        bottom: 0
      })
      $(thumbnail).animate({
        height: '240px'
      })
      $(`#player_${id}`).animate({
        height: '240px'
      })
    }
  }
})

$(document).on('click', '.video_complaint', function () {
  if (userData) {
    if (userData.confirmed) {
      const id = $(this).closest('.feed-item').attr('data-id')
      if ($('.modal').is('.show')) {
        $('.modal').modal('hide')
      }
      showModal('', `
        <h5 class="mb-4">%%Что не так с публикацией?%%</h5>
        <input type="hidden" name="comment" value="${id}">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input type="radio" id="radio1" name="type" class="custom-control-input radio" value="1">
            <label class="custom-control-label" for="radio1">%%Она оскорбителена%%</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="radio2" name="type" class="custom-control-input radio" value="2">
            <label class="custom-control-label" for="radio2">%%Содержит ложную информацию%%</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="radio3" name="type" class="custom-control-input radio" value="3">
            <label class="custom-control-label" for="radio3">%%Непристойное содержание%%</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="radio4" name="type" class="custom-control-input radio" value="4">
            <label class="custom-control-label" for="radio4">%%Отсутствует на источнике%%</label>
          </div>
        </div>
        <button class="btn btn-lg btn-warning mt-4" type="submit">%%Отправить%%</button>
      `, 'auth', () => {
        const validator = $('.validate').validate({
          rules: {
            type: {
              required: true
            }
          },
          messages: {
            type: {
              required: '%%Необходимо выбрать причину жалобы%%'
            }
          },
          submitHandler: () => {
            const spinner  = spinObject($('.modal [type="submit"]'))
            $.ajax({
              dataType: 'json',
              type: 'post',
              url: '/api/article_complaint.json',
              data: $('form[name=confirm-form]').serialize(),
              beforeSend: () => {
                $('.summary-error').remove()
                $('.glass').show()
                spinner.animate({
                  width: 24,
                  'margin-right': 4
                })
              },
              success: (data) => {
                spinner.animate({
                  width: 0,
                  'margin-right': 0
                })
                $('.glass').hide()
                if (data.error) {
                  if (typeof data.error === 'string') {
                    $('form .form-group').last().append(`<div class="summary-error" style="">${data.error}</div>`)
                  } else {
                    validator.showErrors(data.error)
                  }
                } else {
                  $('.modal').modal('hide')
                  showModal('', `
                  <div class="header mb-4 px-2">%%Ваша жалоба зарегистрирована. В ближайшее время она будет рассмотрена. Если публикация действительно не соответствует правилам она будет удалена.%%</div>
                    <button class="btn btn-lg btn-warning mt-3" type="button" data-dismiss="modal">%%Закрыть%%</button>
                `, 'auth')
                }
              },
              error: (jqXHR, textStatus, errorThrown) => {
                window.console.log(jqXHR, textStatus, errorThrown)
              }
            })
          }
        })
      })
    } else {
      showConfirm()
    }
  } else {
    signIn()
  }
})

$(document).on('mouseleave', '.feed-item.thumbed', function () {
  const cover = $(this).find('.cover')
  const id = $(this).attr('data-id')
  if ($(cover).css('display') === 'block') {
    const thumbnail = $(this).find('.thumbnail')
    const footer = $(this).find('.video-footer')
    if (!cover.is('.active')) {
      $(thumbnail).animate({
        height: '100%'
      })
      $(footer).animate({
        bottom: '-80px'
      })
      $(`#player_${id}`).animate({
        height: '100%'
      })
    }
  }
})

$(document).on('click', '.thumbed .cover, .thumbnail', function () {
  const item = $(this).closest('.feed-item')
  const id = $(item).attr('data-id')
  if (!$(`#player_${id}`).is('iframe')) {
    $(this).parent().prepend(`<iframe id="player_${id}" src="${$(item).attr('data-video')}?enablejsapi=1&controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`)
    // eslint-disable-next-line no-undef
    const player = new YT.Player(`player_${id}`, {
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange
      }
    })
    players[`player_${id}`] = player
  } else {
    playItem(players[`player_${id}`])
  }
})

function onPlayerStateChange(event) {
  const player = event.target
  if (event.data === 1) {
    $(player.h).siblings('.thumbnail').css('background-image', 'none')
  }
}

function onPlayerReady(event) {
  const player = event.target
  playItem(player)
}

function playItem(player) {
  const item = $(player.h).closest('.feed-item')
  const cover = $(item).find('.cover')
  const thumbnail = $(item).find('.thumbnail')
  if ($(cover).css('display') === 'block') {
    const footer = $(item).find('.video-footer')
    cover.addClass('active')
    player.unMute()
    player.playVideo()
    const p = $(player.h)[0].getBoundingClientRect()
    $(player.h).css(
      {
        width: p.width,
        height: p.height,
        top: p.top,
        left: p.left,
        position: 'fixed',
        'z-index': 10001
      }
    )
    $('body').append(`
      <div class="modal">
        <a role=button class="text-white float-right mt-3 mr-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  data-dismiss="modal" aria-label="Close">
          <i class="icon-times"></i>
        </a>
      </div>`)
    $('.modal').modal('show')
    $(document).on('hidden.bs.modal', '.modal', function () {
      const modal = $(this)
      $(player.h).animate(
        {
          width: p.width,
          height: p.height,
          top: p.top,
          left: p.left
        }, () => {
          $(player.h).css(
            {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '240px',
              'z-index': 'auto'
            }
          )
          cover.removeClass('active')
          $(document).off('hidden.bs.modal', '.modal')
          modal.remove()
          if (!$(player.h).is(':hover')) {
            $(thumbnail).animate({
              height: '100%'
            })
            $(player.h).animate({
              height: '100%'
            })
            $(footer).animate({
              bottom: '-80px'
            })
          }
        }
      )
      player.pauseVideo()
    })
    $(player.h).animate(calcPosition())
  } else {
    player.unMute()
    player.playVideo()
  }
}

function calcPosition() {
  const width = window.innerWidth
  const height = window.innerHeight
  if (width < height) {
    return {
      width: width * 0.8 * 1.641,
      height: width * 0.8 * 0.609,
      top: (height - width * 0.8 * 0.609) / 2,
      left: width * 0.1
    }
  }
  return {
    width: height * 0.8 * 1.641,
    height: height * 0.8,
    top: height * 0.1,
    left: (width - height * 0.8 * 1.641) / 2
  }
}

document.querySelector('[type=search]').addEventListener('search', (e) => {
  e.preventDefault()
  search = $('[type=search]').val()
  page = 0
  $('.feed').empty()
  feedLoader(post)
})

function translit(str) {
  const sp = '_'
  const text = str.toLowerCase()
  const transl = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'j',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ъ: '\'',
    ы: 'y',
    ь: '',
    э: 'e',
    ю: 'yu',
    я: 'ya',
    '\u00AB':'_',
    '\u00BB':'_', // «»
    ' ': sp,
    _: sp,
    '`': sp,
    '~': sp,
    '!': sp,
    '@': sp,
    '#': sp,
    $: sp,
    '%': sp,
    '^': sp,
    '&': sp,
    '*': sp,
    '(': sp,
    ')': sp,
    '-': sp,
    '=': sp,
    '+': sp,
    '[': sp,
    ']': sp,
    '\\': sp,
    '|': sp,
    '/': sp,
    '.': sp,
    ',': sp,
    '{': sp,
    '}': sp,
    '\'': sp,
    '"': sp,
    ';': sp,
    ':': sp,
    '?': sp,
    '<': sp,
    '>': sp,
    '№': sp
  }
  let result = ''
  let curentSim = ''
  for (let i = 0; i < text.length; i++) {
    if (transl[text[i]] !== undefined) {
      if (curentSim !== transl[text[i]] || curentSim !== sp) {
        result += transl[text[i]]
        curentSim = transl[text[i]]
      }
    } else {
      result += text[i]
      curentSim = text[i]
    }
  }
  result = result.replace(/^_/, '').replace(/_$/, '') // trim
  return result
}

function rgb2hsl(rbg) {
  const r = rbg[0]
  const g = rbg[1]
  const b = rbg[2]
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = 0
  let s = 0
  const l = (max + min) / 2
  if (max !== min) {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break
      case g: h = (b - r) / d + 2; break
      case b: h = (r - g) / d + 4; break
      default:
    }
    h /= 6
  }
  return [h, s, l] // H - цветовой тон, S - насыщенность, L - светлота
}

function changeColor(HTMLcolor) {
  const e = rgb2hsl(HTMLcolor)
  if (e[0] < 0.55 && e[2] >= 0.5 || e[0] >= 0.55 && e[2] >= 0.75) {
    return 'black'
  }
  return 'white'
}

function scrolling() {
  if (section !== 'magazine' && section !== 'managers' && section !== 'docs' && $(this).scrollTop() >= $(this).height() - $(window).height() - 100) {
    feedLoader(post)
  }
}

$('.sidebar-toggler').on('click', () => {
  $('.sidebar, .post-sidebar').toggleClass('open')
  $('.sidebar').toggleClass('ps--scrolling-y', $('.sidebar').is('.open'))
})

$(document).on('click', '.promo-link', function () {
  if ($('.modal').is('.show')) {
    $('.modal').modal('hide')
  }
  const id = $(this).closest('.feed-item').attr('data-id')
  $.ajax({
    dataType: 'json',
    type: 'post',
    url: '/api/promo.json',
    data: `id=${id}`,
    success: (data) => {
      $('.glass').hide()
      if (data.error) {
        window.console.log(data.error)
      }
    },
    error: (jqXHR, textStatus, errorThrown) => {
      window.console.log(jqXHR, textStatus, errorThrown)
    }
  })
})

let profileChange = false
$(document).on('click', '.btn-profile', () => {
  if ($('.modal').is('.show')) {
    $('.modal').modal('hide')
  }
  showModal('', `
    <a class="avatar" style="background-image: url(/img/users/${userData.id}.jpg?${Math.random()})">
      <div class="cover"></div>
      <input class="file-input" type="file" name="avatar">
      <i class="icon-camera"></i>
    </a>
    <div class="form-group mb-3">
      <input name="name" class="form-control form-control-lg" type="text" placeholder="%%Ваше публичное имя%%" value="${userData.name}">
    </div>
    <button class="btn btn-lg btn-warning mt-4" type="submit">%%Оправить%%</button>
    <a class="change-password" role="button">%%Поменять пароль%%</a>
  `, 'auth', () => {
    document.querySelector('.modal .file-input').onchange = function () {
      const url = URL.createObjectURL(this.files[0])
      $(this).closest('.avatar').attr('style', `background-image: url(${url})`)
    }
    const validator = $('.validate').validate({
      rules: {
        name: {
          required: true,
          minlength: 4,
          maxlength: 32
        }
      },
      submitHandler: () => {
        const spinner  = spinObject($('.modal [type="submit"]'))
        const formData = new FormData($('.validate')[0])
        const name  = $('.modal [name="name"]').val()
        $.ajax({
          dataType: 'json',
          type: 'post',
          contentType: false,
          processData: false,
          url: '/api/profile.json',
          data: formData,
          beforeSend: () => {
            $('.summary-error').remove()
            $('.glass').show()
            spinner.animate({
              width: 24,
              'margin-right': 4
            })
          },
          success: (data) => {
            spinner.animate({
              width: 0,
              'margin-right': 0
            })
            $('.glass').hide()
            if (data.error) {
              if (typeof data.error === 'string') {
                $('form .form-group').last().append(`<div class="summary-error" style="">${data.error}</div>`)
              } else {
                validator.showErrors(data.error)
              }
            } else {
              $('.modal').modal('hide')
              $('.profile').each(function () {
                $(this).attr('style', `background-image: url(/img/users/${userData.id}.jpg?${Math.random()})`)
              })
              $('.avatar').each(function () {
                if ($(this).css('background').search(`${userData.id}.jpg`) >= 0) {
                  $(this).attr('style', `background-image: url(/img/users/${userData.id}.jpg?${Math.random()})`).siblings('.name').text(name)
                  $('.username').text(name)
                  userData.name = name
                }
              })
              $('.username').text(name)
              userData.name = name
              profileChange = true
            }
          },
          error: (jqXHR, textStatus, errorThrown) => {
            window.console.log(jqXHR, textStatus, errorThrown)
          }
        })
      }
    })
  })
})

$(document).on('click', '.filter .btn:not(.active)', function () {
  $(this).addClass('active').siblings().removeClass('active')
  page = 0
  $('.feed').empty()
  feedLoader()
})

let lang = false
$.getJSON('/api/flags.json', (data) => {
  const langs = data.langs
  const flags = data.flags

  lang = getCookie('lang')
  if (!lang) {
    lang = data.browser
  }

  langs.forEach((item) => {
    if (lang === item) {
      $('.select-lang .active').append(`<i class="flag-icon flag-icon-squared flag-icon-${flags[item][1]}" data-lang-code="${item}"></i>`)
    } else {
      $('.select-lang .links').append(`<i class="flag-icon flag-icon-squared flag-icon-${flags[item][1]}" data-lang-code="${item}"></i>`)
    }
  })
  if (lang !== 'en' && Object.prototype.hasOwnProperty.call($, 'validator')) {
    $.getScript(`/vendors/localization/messages_${lang}.min.js`)
  }
})


$('.select-lang').on('click', function () {
  $(this).toggleClass('hover')
})

$('.select-lang .links').on('click', 'i', function () {
  lang = $(this).attr('data-lang-code')
  setLang()
})

function setLang() {
  $('.select-lang .links').append($('.select-lang .active i'))
  $('.select-lang .active').append($(`[data-lang-code=${lang}]`))
  document.cookie = `lang=${lang}; path=/;`
  location.reload()
}

export {
  feedLoader,
  getCookie,
  getFormData,
  magazineLoader,
  profileChange,
  section,
  spinObject
}
